import React from 'react';
import Index from './pages/Index';	

//Css 
import "./assets/vendor/switcher/switcher.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/css/style.css";
import "./assets/css/skin/skin-1.css";


function App() {
	return (
		<>	
			<Index /> 
		</>
		
	);
}

export default App;
