import React from 'react';
import LightGallery from 'lightgallery/react';


// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import { IMAGES } from '../constants/theme';

const galleryBlog = [
    {image: IMAGES.bloggrid1},
    {image: IMAGES.bloggrid2},
    {image: IMAGES.bloggrid3},
];

const PortfolioDetails = () => {
    const onInit = () => {
		
	};

    return (
        <>
            <div className="page-wraper">
                <div className="page-content bg-white">
                    <div className="content-inner">
                        <div className="container">
                            <LightGallery
                                onInit={onInit}                            
                                speed={500}
                                plugins={[lgThumbnail, lgZoom]}
                                elementClassNames="row lightgallery justify-content-center"
                                getCaptionFromTitleOrAlt={false}
                                alignThumbnails = "left"
                            >
                                
                                {galleryBlog.map((data,ind)=>(
                                    <div className="col-xl-4 col-md-6 m-lg-b30" key={ind} data-src={data.image}>
                                        <div className="dz-box gallery">
                                            
                                            <div className="dz-media"  >
                                                <img src={data.image} alt="gallery" />
                                            </div>
                                            <div className="content">
                                                <span  className="view-btn lightimg" title="">
                                                    <i className="fa-solid fa-plus"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </LightGallery>	
                        </div>
                    </div> 
                </div>
            </div>
        </>
    );
};

export default PortfolioDetails;