import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { IMAGES, SVGICON } from '../constants/theme';
import LocalizationContext from "../context/LocalizationContext";

const iconBox = [
    {image: IMAGES.aboutlogo1, title: 5},
    {image: IMAGES.aboutlogo2, title: 6},
    {image: IMAGES.aboutlogo3, title: 7}
];

const PerfectTrainers = () => {
    const { t } = React.useContext(LocalizationContext);
    return (
        <>
            <div className="col-lg-6 m-b30">
                <div className="dz-media ">
                    <img src={IMAGES.aboutgirl} alt="" className="wow fadeInUp" data-wow-delay="0.6s" />
                    {SVGICON.multilines}
                    <ul>
                        <li><span>{t("3:9")}</span></li>
                        <li><span>{t("3:10")}</span></li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-6 m-b30 about-content">
                <div className="section-head">
                    <span className="sub-title wow fadeInUp" data-wow-delay="0.2s">{t("3:1")}</span>
                    <h2 className="title wow fadeInUp" data-wow-delay="0.4s">{t("3:2")} <span>{t("3:3")}</span></h2>
                    <p className="wow fadeInUp" data-wow-delay="0.6s">{t("3:4")}</p>
                </div>
                <div className="row m-t40 m-sm-b20 m-b30">
                    {iconBox.map((item, ind)=>(
                        <div className="col-sm-6 m-sm-b20 m-b30" key={ind}>
                            <div className="icon-bx-wraper style-2">
                                <div className="icon-bx"> 
                                    <span className="icon-cell">
                                        <img src={item.image} alt="" />
                                    </span>
                                </div>
                                <div className="icon-content">
                                    <h5 className="dz-title">{t(`3:${item.title}`)}</h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="clearfix wow fadeInUp" data-wow-delay="1.0s">
                    <HashLink smooth to={"#team"} className="btn btn-skew btn-lg btn-primary shadow-primary"><span>{t("3:8")}</span></HashLink>
                </div>
            </div>                    
        </>
    );

}
export default PerfectTrainers;