import React from 'react';
import {Accordion} from 'react-bootstrap';
import LocalizationContext from "../context/LocalizationContext";

const accordBlog = [

    {
        content : [
            {
                title : 1,
                text : 2
            },
            {
                title : 3,
                text : 4
            },
            {
                title : 5,
                text : 6
            },
            {
                title : 13,
                text : 14
            }
        ],
    },
    {
        content : [
            {
                title : 7,
                text : 8
            },
            {
                title : 9,
                text : 10
            },
            {
                title : 11,
                text : 12
            }
        ],
    }

];

const Faq = () => {
    const { t } = React.useContext(LocalizationContext);
    return (
        <>
                <section className="content-inner-2 overflow-hidden">
                    <div className="container">
                        <div className="row"> 
                        <div className="section-head">
                        <h2 className="title">FAQ</h2>
                        </div>          
                            {accordBlog.map((data, index)=>{
                                return(
                                    <div className="col-lg-6" key={index}>
                                        <Accordion className="dz-accordion dz-accordion-skew" id="accordionFaq2" defaultActiveKey={0}>
                                            {data.content.map((item, index)=>(
                                                <Accordion.Item className="accordion-item" key={index} eventKey={index}>
                                                    <Accordion.Header as="h2"  id="headingOne1">
                                                    {t(`8:${item.title}`)}
                                                        <span className="toggle-close"></span>
                                                    </Accordion.Header>
                                                    <div id="collapseOne1" className="accordion-collapse " eventKey={index}>
                                                        <Accordion.Body >
                                                            <p className="m-b0">
                                                            {t(`8:${item.text}`)}
                                                            </p>
                                                        </Accordion.Body>
                                                    </div>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion> 
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>
        </>
    );
};

export default Faq;