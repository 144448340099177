import React, { useState } from 'react';
import ModalVideo from 'react-modal-video'
import { Link } from 'react-router-dom';
import 'react-modal-video/css/modal-video.min.css';
import CookieConsent from "react-cookie-consent";
import FitnessGoal from '../components/FitnessGoal';

//Components
import MainBanner from '../components/MainBanner';
import PerfectTrainers from '../components/PerfectTrainers';
//import ScheduleTable from '../elements/ScheduleTable';
import Memberships from './Pricing';
import Services from './Services';
import Schedule from './Schedule';
import Team from './Team';
import FAQ from './Faq';
import Contact from './ContactUs';
import { IMAGES } from '../constants/theme';
import WorkoutSlider from '../elements/WorkoutSlider';
import LatestSlider from '../elements/LatestSlider';
import LocalizationContext from "../context/LocalizationContext";

const Home = () => {
    const [isOpen, setOpen] = useState(false);
    const { t } = React.useContext(LocalizationContext);
    return (
        <>
            <div id="home" className="page-content bg-white">
                <div className="main-bnr-one">
                    <MainBanner isOpenModal={setOpen} />
                </div>
                <section id="products" className="content-inner portfolio-wrapper">
                    <div className="portfolio-wrapper-inner">
                        <div className="container-fluid  p-0">
                            <LatestSlider />
                        </div>
                    </div>
                    <svg className="shape-up" width="635" height="107" viewBox="0 0 635 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M577 0L-16 107L635 45L577 0Z" fill="var(--primary-dark)" />
                    </svg>
                    <svg className="shape-down" width="673" height="109" viewBox="0 0 673 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M682 0L0 56L682 109V0Z" fill="var(--primary)" />
                    </svg>
                </section>
                <section className="content-inner about-wrapper1 about-bx1">
                    <div className="container">
                        <div className="row align-items-center">
                            <PerfectTrainers />
                        </div>
                    </div>
                </section>
                <section id="about" className="content-inner about-wrapper2" style={{ backgroundImage: "url(" + IMAGES.BgImage3 + ")", backgroundPosition: "center", backgroundSize: "cover" }}>
                    <div className="container">
                        <div className="row about-bx2 align-items-center">
                            <FitnessGoal isOpenModal={setOpen} />
                        </div>
                    </div>
                </section>

                <section id={"membership"} className="content-inner overflow-hidden" style={{ backgroundImage: "url(" + IMAGES.BgImage1 + ")" }}>
                    <div className="container">
                        <Memberships />
                    </div>
                </section>
                <section id={"services"} className="content-inner overflow-hidden" style={{ backgroundImage: "url(" + IMAGES.BgImage1 + ")" }}>
                    <div className="container">
                        <Services />
                    </div>
                </section>
                <section id="gallery" className="content-inner portfolio-wrapper">
                    <div className="portfolio-wrapper-inner">
                        <div className="container-fluid  p-0">
                            <WorkoutSlider />
                        </div>
                    </div>
                    <svg className="shape-up" width="635" height="107" viewBox="0 0 635 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M577 0L-16 107L635 45L577 0Z" fill="var(--primary-dark)" />
                    </svg>
                    <svg className="shape-down" width="673" height="109" viewBox="0 0 673 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M682 0L0 56L682 109V0Z" fill="var(--primary)" />
                    </svg>
                </section>
                <section id="team" className="content-inner overflow-hidden" style={{ backgroundImage: "url(" + IMAGES.BgImage1 + ")" }}>
                    <div className="container">
                        <Team />
                    </div>
                </section>
                <section id="schedule" className="content-inner overflow-hidden" style={{ backgroundImage: "url(" + IMAGES.BgImage1 + ")" }}>
                    <div className="container">
                        <Schedule />
                    </div>
                </section>
                <section id="faq">
                    <div className="content-inner overflow-hidden" style={{ backgroundImage: "url(" + IMAGES.BgImage1 + ")" }}>
                        <FAQ />
                    </div>
                </section>
                <section id={"contact"} className="content-inner overflow-hidden" style={{ backgroundImage: "url(" + IMAGES.BgImage1 + ")" }}>
                    <div className="container">
                        <Contact />
                    </div>
                </section>
            </div>
            <ModalVideo channel='youtube' youtube={{ autoplay: 1 }} isOpen={isOpen} videoId="D--ERqIxu-U" onClose={() => setOpen(false)} />
            <CookieConsent
                location="bottom"
                buttonText="OK"
                cookieName="Fit4HerPolicy"
                style={{ background: "#2B373B" }}
                buttonStyle={{background: "#ff3292", color: "#ffffff", fontSize: "13px" }}
                expires={365}
            >{t("10:19")}<Link to={"https://www.freeprivacypolicy.com/live/dc02ed13-d6be-4831-94cd-2bbda1a44024"} target='_blank'>{t("10:20")}</Link>
            </CookieConsent>
        </>
    );
};

export default Home;