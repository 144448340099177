import React from 'react';
import { IMAGES } from '../constants/theme';
import { HashLink } from 'react-router-hash-link';
import LocalizationContext from "../context/LocalizationContext";

const Footer = () => {
    let update = new Date();
    const { t } = React.useContext(LocalizationContext);
    return (
        <>
            <footer className="site-footer style-1 bg-img-fix footer-action" style={{backgroundImage: "url("+ IMAGES.footerbg +")"}} id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-md-12">
                                <div className="widget widget_about">
                                    <div className="footer-logo logo-dark">
                                        <HashLink to={"#schedule"}><img src={IMAGES.logo} alt="" /></HashLink> 
                                    </div>
                                    <p>{t("10:1")}</p>
                                    <h6 className="m-b15">{t("10:2")}</h6>
                                    <div className="dz-social-icon style-1">
                                        <ul>									
                                            <li>
                                                <a target="_blank" href="https://m.facebook.com/profile.php?id=100095082023703" rel="noreferrer">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </li>{" "}
                                            <li>
                                                <a target="_blank" href="https://instagram.com/fit4her_leuven?igshid=MzMyNGUyNmU2YQ==" rel="noreferrer">
                                                    <i className="fab fa-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 wow fadeInUp" data-wow-delay="0.6s">
                                <div className="widget widget_locations">
                                    <h4 className="footer-title">{t("10:3")}</h4>
                                    
                                    <div className="widget widget_getintuch ">
                                        <ul>
                                            <li>
                                                <i className="fa-solid fa-location-dot"></i>
                                                <p style={{color: "black"}}>Bondgenotenlaan 151 00.01, 3000 Leuven</p>
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-envelope"></i>
                                                <p style={{color: "black"}}>info.fit4herleuven@gmail.com</p>
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-phone"></i>
                                                <p style={{color: "black"}}>016440855</p>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 wow fadeInUp" data-wow-delay="0.8s">
                                <div className="widget widget_working">
                                    <h4 className="footer-title">{t("10:4")}</h4>
                                    <ul>
                                        <li>
                                            <span className="time">{t("10:5")} <b>{t("10:6")}</b></span>
                                        </li>
                                        <li>
                                            <span className="time">{t("10:7")} <b>{t("10:8")}</b></span>
                                        </li>
                                        <li>
                                            <span className="time">{t("10:9")} <b>{t("10:10")}</b></span>
                                        </li>
                                        <li>
                                            <span className="time">{t("10:11")} <b>{t("10:12")}</b></span>
                                        </li>
                                        <li>
                                            <span className="time">{t("10:13")} <b>{t("10:14")}</b></span>
                                        </li>
                                        <li>
                                            <span className="time">{t("10:15")} <b>{t("10:16")}</b></span>
                                        </li>
                                        <li>
                                            <span className="time">{t("10:17")} <b>{t("10:18")}</b></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Footer Bottom Part --> */}
                <div className="container">
                    <div className="footer-bottom">
                        <div className="text-center"> 
                            <span className="copyright-text">Copyright © {update.getFullYear()} Fit4Her. All rights reserved.</span> 
                        </div>
                    </div>
                </div>
                <img className="girl-img" src={IMAGES.footergril1} alt="" />
                <img className="svg-shape-1 rotate-360" src={IMAGES.footercircle} alt="" />
                <img className="svg-shape-2 rotate-360" src={IMAGES.footercircle} alt="" />
            </footer>
        </>
    );
};

export default Footer;