import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from "./context/lang/en";
import nl from "./context/lang/nl";

const resources = {
  "en": en, 
  "nl": nl, 
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'nl'
  });

export default i18n;
