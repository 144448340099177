import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
//import ScrollToTop2 from "react-scroll-to-top";

//layouts
import Header from './../layouts/Header';
import Footer from './../layouts/Footer';
import ScrollToTop from './../layouts/ScrollToTop';

//Pages
import Home from './Home';
import AboutUs from './AboutUs';
import Team from './Team';
import Pricing from './Pricing';
import Faq from './Faq';
import Schedule from './Schedule';
import ErrorPage from './ErrorPage';
import UnderConstruction from './UnderConstruction';
import ComingSoon from './ComingSoon';
import Portfolio from './Portfolio';
import PortfolioDetails from './PortfolioDetails';
import Services from './Services';
import ServicesDetails from './ServicesDetails';
import Products from './Products';
import BlogLargeSidebar from './BlogLargeSidebar';
import BlogListSidebar from './BlogListSidebar';
import BlogDetail from './BlogDetail';
import Appointment from './Appointment';
import WeightCalculator from './WeightCalculator';
import ContactUs from './ContactUs';

function Index(){
	//var baseName = '';
	return(
		<BrowserRouter>			
			<Routes>
				<Route path='/error-404' exact element={<ErrorPage />} />
				<Route path='/under-maintenance' exact element={<UnderConstruction />} />
				<Route path='/appointment' exact element={<Appointment/>} />
				<Route path='/coming-soon' exact element={<ComingSoon/>} />
				
				<Route  element={<MainLayout />} > 
					<Route path='/' exact element={<Home />} />									
					<Route path='/about-us' exact element={<AboutUs />} />
					<Route path='/team' exact element={<Team />} />
					<Route path='/faq' exact element={<Faq />} />
					<Route path='/pricing' exact element={<Pricing />} />
					<Route path='/schedule' exact element={<Schedule />} />
					<Route path='/portfolio' exact element={<Portfolio />} />
					<Route path='/portfolio-details' exact element={<PortfolioDetails />} />
					<Route path='/services' exact element={<Services />} />
					<Route path='/services-details' exact element={<ServicesDetails/>} />
					<Route path='/products' exact element={<Products/>} />
					<Route path='/blog-large-sidebar' exact element={<BlogLargeSidebar/>} />
					<Route path='/blog-list-sidebar' exact element={<BlogListSidebar/>} />
					<Route path='/blog-details' exact element={<BlogDetail/>} />
					<Route path='/weight-calculator' exact element={<WeightCalculator/>} />
					<Route path='/contact-us' exact element={<ContactUs/>} />					
				</Route> 
			</Routes>									
		<ScrollToTop />		
		</BrowserRouter>			
	)
} 

function MainLayout(){
	
	return (
		<div className="page-wraper">			
			<Header />
			<Outlet />                
			<Footer  />
	  	</div>
	)
  
  };
export default Index;
