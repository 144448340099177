import React, { useRef } from 'react';
// import { Dropdown } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
//import swal from "sweetalert";
import Select from 'react-select';

const options = [
    { value: '0', label: 'Select Service' },
    { value: '1', label: 'Strength Center' },
    { value: '2', label: 'Fitness' },
    { value: '3', label: 'Muscle Bar' },
]

const Appointment = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
        emailjs.sendForm('service_gfykn6i', 'template_iy1pb0b', e.target, 'HccoOtZS6GHw-N-m6')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
        //swal('Good job!', 'We have received your message successfully.Thanks for Contact.', "success");
    };
    return (
        <>
            <div >
                <section className="appointment-page" data-text="HEALTH" >
                    <div className="container">
                        <div className="section-head">
                            <span className="sub-title">Get in Touch</span>
                            <h2 className="title">Schedule An <span>Appointment</span></h2>
                        </div>
                        <form className="appointment-form dzForm" ref={form} onSubmit={sendEmail}>
                            <p className="appointment-text">
                                Hey, My Name is{"  "}
                                <input name="dzFirstName" required type="text" className="form-control" placeholder="Name" /> {" "}
                                And i'm looking for
                                <Select
                                    options={options}
                                    defaultValue={options[0]}
                                    isSearchable={false}
                                    //menuIsOpen = {true}

                                    className="custom-react-select ms-2"
                                />

                                Get in consult with us at {" "}
                                <input name="dzEmail" required type="text" className="form-control" placeholder="Your Email ID Here" /> {" "}
                                !
                            </p>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />{" "}
                                <label className="form-check-label" for="flexCheckDefault">I here by accept all terms and conditions.</label>
                            </div>
                            <button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg btn-skew"><span>Appointment</span></button>
                        </form>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Appointment;