import React from 'react';
import {Link} from 'react-router-dom';
import { IMAGES } from '../constants/theme';
import LocalizationContext from "../context/LocalizationContext";

const mediaBlog = [
    {images: IMAGES.team2, title: 'BRITT', role: 2},
    {images: IMAGES.team3, title: 'AMIRA', role: 3},
    {images: IMAGES.team4, title: 'YASMIN', role: 3},
    {images: IMAGES.team5, title: 'ZAYNAB', role: 3},
    {images: IMAGES.team6, title: 'ZURAYA', role: 3},
    {images: IMAGES.team1, title: 'NOUSHIN', role: 3},
    {images: IMAGES.team7, title: 'SELMA', role: 3}
];

const Team = () => {
    const { t } = React.useContext(LocalizationContext);
    return (
        <>
            <div className="page-content bg-white">
                <section className="content-inner">
                    <div className="section-head">
                        <h2 className="title">{t("7:1")}</h2>
                        </div>       
                        <div className="row ">
                            {mediaBlog.map((item, index)=>(
                                <div className="col-lg-4 col-sm-6 m-b30" key={index}>
                                    <div className="dz-team style-1">
                                        <div className="dz-media">
                                            <Link to={"#"}><img src={item.images} alt="" /></Link>
                                            {/* <ul className="team-social">
                                                <li>
                                                    <a target="_blank" href="https://www.facebook.com/" rel="noreferrer"> 
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://twitter.com/?lang=en" rel="noreferrer">
                                                        <i className="fab fa-twitter"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.instagram.com/?hl=en" rel="noreferrer">
                                                        <i className="fab fa-instagram"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.instagram.com/?hl=en" rel="noreferrer">
                                                        <i className="fab fa-instagram"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.instagram.com/?hl=en" rel="noreferrer">
                                                        <i className="fab fa-instagram"></i>
                                                    </a>
                                                </li>
                                            </ul> */}
                                        </div>
                                        <div className="dz-content ">
                                            <h4 className="dz-name">{item.title}</h4>
                                            <span className="dz-position">{t(`7:${item.role}`)}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                </section>
            </div>   
        </>
    );
};

export default Team;