import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination } from "swiper";
import { IMAGES } from "../constants/theme";
import LocalizationContext from "../context/LocalizationContext";

    

const dataBlog = [
    {image: IMAGES.product1, title:"Magnesium/MSM serum", text:2, },
    {image: IMAGES.product2, title:"Lava Klei (Ghassoul)", text:3, },
	{image: IMAGES.product3, title:"Magnesium vlokken (750 gram)", text:4, },
    {image: IMAGES.product4, title:"Shea Butter (Koudgeperst & Ongeraffineerd) (150g)", text:5, }
]; 


function changeItemBoxed() {
    let  bodyClass = document.body.classList;
    //console.log(bodyClass.contains);
    if(bodyClass.contains === "boxed") {
        return 3;
    } else {
        return 4;
    }
}


function LatestSlider() {
	const navigationPrevRef = React.useRef(null)
	const navigationNextRef = React.useRef(null)
    const paginationRef = React.useRef(null)
    const { t } = React.useContext(LocalizationContext);
	return (
		<div className="container">			
            <div className="row justify-content-between align-items-center m-b10">
                <div className="col-xl-7">
                    <div className="section-head text-center text-md-start">
                        <h2 className="title">{t("12:1")}</h2>
                    </div>
                </div>
                <div className="col-xl-5 text-md-end d-flex align-items-center justify-content-xl-end justify-content-sm-between justify-content-center m-sm-b30 m-b40">
                    <div className="num-pagination">
                        <div className="swiper-blog-prev btn-prev" ref={navigationPrevRef}><i className="fa-solid fa-arrow-left"></i></div>
                        <div className="swiper-blog-pagination1 swiper-pagination style-1" ref={paginationRef}></div>
                        <div className="swiper-blog-next btn-next" ref={navigationNextRef}><i className="fa-solid fa-arrow-right"></i></div>
                    </div>
                </div>
            </div>
            <Swiper className="swiper blog-slider-full blog-slider-wrapper"	
                centeredSlides={true}
                slidesPerView={changeItemBoxed()}
                spaceBetween= {30}
                loop={true}
				speed={1500}
                autoplay ={{
                    delay:3000
                }}
                pagination= {{
                    el : '.swiper-blog-pagination1',
                    clickable: true,
                    renderBullet: function (index, className) {	
                        return '<span class="' + className + '"> 0'+ (index + 1) +'</span>';
                    },
                }}
                								
				onSwiper={(swiper) => {
					setTimeout(() => {
						swiper.params.navigation.prevEl = navigationPrevRef.current
						swiper.params.navigation.nextEl = navigationNextRef.current
						swiper.navigation.destroy()
						swiper.navigation.init()
						swiper.navigation.update()
					})
				}}
                modules={[Navigation, Pagination]}      
                breakpoints = {{
                    1200: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                        centeredSlides: false,
                    },
                    320: {
                        slidesPerView: 1,
                        centeredSlides: false,
                    },
                    
                }}	          					
            >	
            
                {dataBlog.map((item,ind)=>(
                    <SwiperSlide key={ind}>                       
                        <div className="dz-card style-1 overlay-shine">
                            <div className="dz-media">
                                <img src={item.image} alt="" />
                            </div>
                            <div className="dz-info">
                                <h6 className="dz-title">{item.title}</h6>
                                <p style={{fontSize:12}}>{t(`12:${item.text}`)}</p>
                            </div>
                        </div>
                    </SwiperSlide>                    
                ))}	
               
                								
            </Swiper>			
		</div>
	)
}
export default LatestSlider;