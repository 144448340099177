import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination } from "swiper";
import { IMAGES } from "../constants/theme";

    

const dataBlog = [
	{ image: IMAGES.portfolio1, title:"🏋🏽", changestyle:"box-1"},
	{ image: IMAGES.portfolio2, title:"💪🏼", changestyle:"box-2"},
	{ image: IMAGES.portfolio3, title:'🔥', changestyle:"box-1"},
    { image: IMAGES.portfolio4, title:'🎧', changestyle:"box-1"},
    { image: IMAGES.portfolio5, title:'🤾‍♀️', changestyle:"box-2"},
    { image: IMAGES.portfolio6, title:'🩷', changestyle:"box-1"},
	
]; 

function WorkoutSlider() {
	const navigationPrevRef = React.useRef(null) 
	const navigationNextRef = React.useRef(null)
    const paginationRef = React.useRef(null)
	return (
		<>			
            <Swiper className="swiper portfolio-slider"	
               // centeredSlides={true}
                slidesPerView={"auto"}
                spaceBetween= {0}
                loop={true}
				speed={1500}
                pagination= {{
                    el : '.swiper-pagination',
                    // el: paginationRef.current,
                    clickable: true,
                    renderBullet: function (index, className) {									
                        // return '<div class="' + className + '">'+ (dataBlog[index].datatitle) +'<span> 0'+ (index + 1) +'</span>' + '</div>';
                        return '<span class="' + className + '"> 0'+ (index + 1) +'</span>';
                      },
                }}
                //autoplay= {{
                //    delay: 4500,
                //}}								
				onSwiper={(swiper) => {
					setTimeout(() => {
						swiper.params.navigation.prevEl = navigationPrevRef.current
						swiper.params.navigation.nextEl = navigationNextRef.current
						swiper.navigation.destroy()
						swiper.navigation.init()
						swiper.navigation.update()
					})
				}}
                modules={[Navigation, Pagination]}
              				
            >	
            
                {dataBlog.map((item,ind)=>(
                    <SwiperSlide key={ind}>                       
                        <div className={`dz-box style-1 ${item.changestyle}`}>
                            <div className="dz-media">
                                <img src={item.image} alt="" />
                            </div>
                            <div className="dz-info">
                                <h3 className="title">{item.title}</h3>
                            </div>
                        </div>
                    </SwiperSlide>                    
                ))}	
               
                <div className="container">
                    <div className="num-pagination">
                        <div className="portfolio-button-prev btn-prev dark" ref={navigationPrevRef}><i className="fa-solid fa-arrow-left"></i></div>
                        <div className="swiper-pagination dark style-1 m-lr-lg" ref={paginationRef}></div>
                        <div className="portfolio-button-next btn-next dark" ref={navigationNextRef}><i className="fa-solid fa-arrow-right"></i></div>
                    </div>
                </div>								
            </Swiper>			
		</>
	)
}
export default WorkoutSlider;