import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { IMAGES } from '../constants/theme';
import LocalizationContext from "../context/LocalizationContext";

const  wrapperBlog = [
    {title: 2, text: 6, image:IMAGES.boxlog7},
    {title: 3, text: 7, image:IMAGES.boxlog2},
    {title: 4, text: 8, image:IMAGES.boxlog3},
    {title: 5, text: 9, image:IMAGES.boxlog4}
];

const Services = () => {
    const [hover, setHover] = useState(0);
    const { t } = React.useContext(LocalizationContext);
    return (
        <>
            <div className="page-content bg-white">
                <section style={{backgroundImage: "url("+ IMAGES.BgImage1 +")"}}>
                    <div className="container">
                        <div className="section-head">
                        <h2 className="title">{t("6:1")}</h2>
                        </div>    
                        <div className="row">
                            {wrapperBlog.map((item, index)=>(
                                <div className="col-xl-3 col-md-6 m-b30" key={index}>
                                    <div className={`icon-bx-wraper style-1 box-hover ${hover === index ? 'active' : ''}`}
                                        onMouseEnter={()=>setHover(index)}
                                    >
                                        <div className="icon-bx m-b30"> 
                                            <span className="icon-cell">
                                                <img src={item.image} alt="" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <h5 className="dz-title m-b10"><Link to={"#"}>{t(`6:${item.title}`)}</Link></h5>
                                            <p className="m-b25">{t(`6:${item.text}`)}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>    
                    </div>
                </section>
            </div>   
        </>
    );
};

export default Services;