import React,{useRef} from 'react';
import emailjs from '@emailjs/browser';
import swal from "sweetalert2";
import LocalizationContext from "../context/LocalizationContext";

const ContactUs = () => {
    const form = useRef();
    const { t } = React.useContext(LocalizationContext);
	const sendEmail = (e) => {
		e.preventDefault();
		//emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
		emailjs.sendForm('service_rssyaek', 'template_l6spdkk', e.target, '8E9eFLM42Vj1OL32A')
		  .then((result) => {
			  console.log(result.text);
		  }, (error) => {
			  console.log(error.text);
		  });
		  e.target.reset()
          swal.fire({
            title: t("9:1"),
            text: t("9:2"),
            icon: "success"
          });
	};
    return (
        <>
            <div className="page-content bg-white">
                <section className="content-inner-2 z-index-none">	
                    <div className="container">
                    <div className="section-head">
                        <h2 className="title">Contact</h2>
                        </div>  
                        <div className="row align-items-center">
                            <div className="col-md-6 col-xl-5 m-sm-b30 m-xl-b0">	
                                <div className="contact-box">
                                    <h3 className="contact-title">{t("9:4")}</h3>
                                    <p className="contact-text">{t("9:5")}</p>
                                    <div className="widget widget_getintuch ">
                                        <ul>
                                            <li>
                                                <i className="fa-solid fa-location-dot"></i>
                                                <p>Bondgenotenlaan 151 00.01, 3000 Leuven</p>
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-envelope"></i>
                                                <p>info.fit4herleuven@gmail.com</p>
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-phone"></i>
                                                <p>016440855</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <h6 className="m-b15 text-white">{t("9:6")}</h6>
                                    <div className="dz-social-icon style-1 dark">
                                        <ul>
                                            <li><a target="_blank" href="https://m.facebook.com/profile.php?id=100095082023703" rel="noreferrer">
                                                <i  className="fab fa-facebook-f" />
                                                </a>
                                            </li>{" "}
                                            <li><a target="_blank" href="https://instagram.com/fit4her_leuven?igshid=MzMyNGUyNmU2YQ==" rel="noreferrer">
                                                <i className="fab fa-instagram" />
                                                </a></li>
                                        </ul>
                                    </div>
                                    <svg width="250" height="70" viewBox="0 0 250 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 38L250 0L210 70L0 38Z" fill="url(#paint0_linear_306_1296)"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_306_1296" x1="118.877" y1="35.552" x2="250.365" y2="35.552" gradientUnits="userSpaceOnUse">
                                            <stop offset="1" stopColor="var(--primary)"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-7">
                                <form className="dz-form dzForm style-1" ref={form} onSubmit={sendEmail}>
                                    <input type="hidden" className="form-control" name="dzToDo" value="Contact" />
                                    <div className="dzFormMsg"></div>
                                    
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="input-group input-line">
                                                <input name="firstName" required type="text" className="form-control" placeholder={t("9:7")} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="input-group input-line">
                                                <input name="lastName" required type="text" className="form-control" placeholder={t("9:8")} />
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="input-group input-line">
                                                <input name="email" required type="text" className="form-control" placeholder={t("9:9")} />
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="input-group input-line">
                                                <input name="phoneNumber" required type="text" className="form-control" placeholder={t("9:10")} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="input-group input-line m-b30">
                                                <textarea name="message" rows="5" required className="form-control" placeholder={t("9:11")}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg btn-skew"><span>{t("9:12")}</span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container content-inner-1">
                    <div className="map-iframe">
                        <iframe title="myFrame"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2517.313064956359!2d4.709762976451484!3d50.88091585627654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c160d3800e655f%3A0x5223a33f2b54ee3c!2sBondgenotenlaan%20151%2C%203000%20Leuven%2C%20Belgium!5e0!3m2!1sen!2ske!4v1695550472876!5m2!1sen!2ske" style={{border:"0", marginBottom: "-7px", width: "100%"}}  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>
            </div>   
        </>
    );
};
export default ContactUs;