import React from 'react';
import ScheduleTable from '../elements/ScheduleTable';
import LocalizationContext from "../context/LocalizationContext";
const Schedule = () => {
    const { t } = React.useContext(LocalizationContext);
    return (
        <>
            <section className="content-inner-2 overflow-hidden">
                    <div className="container">
                        <div className="row"> 
                        <div className="section-head">
                        <h2 className="title">{t("11:1")}</h2>
                        <div className="schedule-table table-responsive">
                            <ScheduleTable />
                        </div>
                        </div>
                     
                </div>
            </div>  
            </section>
        </>
    );
};

export default Schedule;