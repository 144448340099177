export const MenuListArray2 = [
    {
        id: 2,
        title: 'Thuis',	
        to: '#home',
    },
    {
        id: 3,
        title: 'Over ons',	
        to: '#about',
    },
    {
        id: 4,
        title: 'lidmaatschappen',	
        to: '#membership',
    },
    {
        id: 5,
        title: 'Diensten',	
        to: '#services',
    },
    {
        id: 6,
        title: 'Galerij',	
        to: '#gallery',
    },
    {
        id: 7,
        title: 'Contact',	
        to: '#contact',
    }
]