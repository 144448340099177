import React from 'react';
import {Link} from 'react-router-dom';
import LocalizationContext from "../context/LocalizationContext";

const ScheduleTable = () => {
    const { t } = React.useContext(LocalizationContext);
    return (
        <>
            <table className="table-responsive-sm block-table">
                <thead>
                    <tr>
                        <th></th>
                        {/* <th>{t("10:5")}</th>
                        <th>{t("10:7")}</th> */}
                        <th className="event-time">{t("10:7")}</th>
                        <th className="event-time">{t("10:9")}</th>
                        <th className="event-time">{t("10:13")}</th>
                        <th className="event-time">{t("10:15")}</th>
                        {/* <th>{t("10:17")}</th> */}
                    </tr>
                </thead>
                <tbody>
                    {/* <tr className="row_1">
                        <td className="event-time">{t("11:8")}</td>
                        <td className="event">
                            <Link to={"#"} className="title" >{t("11:2")}</Link>
                            <span className="subtitle">Max 4 {t("11:7")}</span>
                            <div className="event-tag">{t("11:2")}</div>
                        </td>
                    </tr> */}
                    {/* <tr className="row_2">
                        <td className="event-time">{t("11:9")}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr> */}
                    <tr className="row_3">
                        <td className="event-time">{t("11:10")}</td>
                        <td></td>
                        <td className="event">
                            <Link to={"#"} className="title" >{t("11:4")}</Link>
                            <span className="subtitle">Max 4 {t("11:7")}</span>
                            <div className="event-tag">{t("11:4")}</div>
                        </td>
                    </tr>
                    <tr className="row_4">
                        <td className="event-time">{t("11:11")}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="event">
                            <Link to={"#"} className="title" >{t("11:3")}</Link>
                            <span className="subtitle">Max 5 {t("11:7")}</span>
                            <div className="event-tag">{t("11:3")}</div>
                        </td>
                    </tr>
                    <tr className="row_5">
                        <td className="event-time">{t("11:13")}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="event">
                            <Link to={"#"} className="title" >{t("11:6")}</Link>
                            <span className="subtitle">Max 4 {t("11:7")}</span>
                            <div className="event-tag">{t("11:6")}</div>
                        </td>
                    </tr>
                    <tr className="row_6">
                        <td className="event-time">{t("11:19")}</td>
                        <td></td>
                        <td className="event">
                            <Link to={"#"} className="title" >{t("11:2")}</Link>
                            <span className="subtitle">Max 4 {t("11:7")}</span>
                            <div className="event-tag">{t("11:2")}</div>
                        </td>
                    </tr>
                    <tr className="row_7">
                        <td className="event-time">{t("11:20")}</td>
                        <td className="event">
                            <Link to={"#"} className="title" >{t("11:3")}</Link>
                            <span className="subtitle">Max 5 {t("11:7")}</span>
                            <div className="event-tag">{t("11:4")}</div>
                        </td>
                    </tr>
                    {/* <tr className="row_8">
                        <td className="event-time">{t("11:15")}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr> */}
                    {/* <tr className="row_9">
                        <td className="event-time">{t("11:16")}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr> */}
                    {/* <tr className="row_10">
                        <td className="event-time">{t("11:17")}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr> */}
                    {/* <tr className="row_11">
                        <td className="event-time">{t("11:18")}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr> */}
                    {/* <tr className="row_12">
                        <td className="event-time">{t("11:19")}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr> */}
                    {/* <tr className="row_13">
                        <td className="event-time">{t("11:20")}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr> */}
                    <tr className="row_14">
                        <td className="event-time">{t("11:211")}</td>
                        <td className="event">
                            <Link to={"#"} className="title" >{t("11:5")}</Link>
                            <span className="subtitle">Max 6 {t("11:7")}</span>
                            <div className="event-tag">{t("11:5")}</div>
                        </td>
                        <td></td>
                    </tr>
                    {/* <tr className="row_15">
                        <td className="event-time">{t("11:21")}</td>
                        <td className="event">
                            <Link to={"#"} className="title" >{t("11:5")}</Link>
                            <span className="subtitle">Max 6 {t("11:7")}</span>
                            <div className="event-tag">{t("11:5")}</div>
                        </td>
                    </tr> */}
                    <tr className="row_16">
                        <td className="event-time">{t("11:22")}</td>
                        <td></td>
                        <td></td>
                        <td className="event">
                            <Link to={"#"} className="title" >{t("11:4")}</Link>
                            <span className="subtitle">Max 4 {t("11:7")}</span>
                            <div className="event-tag">{t("11:4")}</div>
                        </td>
                    </tr>
                    <tr className="row_17">
                        <td className="event-time">{t("11:23")}</td>
                        <td></td>
                        <td></td>
                        <td className="event">
                            <Link to={"#"} className="title" >{t("11:4")}</Link>
                            <span className="subtitle">Max 4 {t("11:7")}</span>
                            <div className="event-tag">{t("11:4")}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default ScheduleTable;