import React, { useState, useEffect, useReducer } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IMAGES } from '../constants/theme';
import { HashLink } from 'react-router-hash-link';
import Collapse from 'react-bootstrap/Collapse';
import { MenuListArray2 } from './MenuListArray2';
import LocalizationContext from "../context/LocalizationContext";

const Header = () => {
    const { t } = React.useContext(LocalizationContext);
    const { i18n } = React.useContext(LocalizationContext);
    /* for sticky header */
    const [headerFix, setheaderFix] = React.useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setheaderFix(window.scrollY > 50);
        });
    }, []);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        var mainMenu = document.getElementById('OpenMenu');
        if (mainMenu) {
            if (sidebarOpen) {
                mainMenu.classList.add('show');
            } else {
                mainMenu.classList.remove('show');
            }
        }
    });

    // Menu dropdown list 
    const reducer = (previousState, updatedState) => ({
        ...previousState,
        ...updatedState,
    });
    const initialState = {
        active: "",
        activeSubmenu: "",
    }
    const [state, setState] = useReducer(reducer, initialState);
    const handleMenuActive = status => {
        setSidebarOpen(false);
        setActiveMenu(status);
        setState({ active: status });
        if (state.active === status) {
            setState({ active: "" });
        }
    }
    const handleSubmenuActive = (status) => {
        setState({ activeSubmenu: status })
        if (state.activeSubmenu === status) {
            setState({ activeSubmenu: "" })
        }
    }

    const location = useLocation();
    //console.log(location.pathname);

    const [activeMenu, setActiveMenu] = useState('');

    return (
        <>
            <header className="site-header mo-left header header-transparent style-1">
                <div className="top-bar">
                    <div className="container">
                        <div className="dz-topbar-inner d-flex justify-content-between align-items-center">
                            <div className="dz-topbar-left">
                                <ul>
                                    <li><i className="fa-regular fa-envelope"></i>info.fit4herleuven@gmail.com</li>
                                </ul>
                            </div>
                            <div className="dz-topbar-right">
                                <ul>
                                    <li><i className="fa fa-clock"></i><HashLink to={"#footer"} className="btn-link" ><i className="fa fa-arrow-right"> {t("1:1")}</i></HashLink></li>{" "}
                                    <li><i className="fa fa-phone"></i>016440855</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix ? "is-fixed" : ""}`}>
                    <div className="main-bar clearfix">
                        <div className="container clearfix">
                            <div className="box-header clearfix">

                                {/* <!-- Website Logo --> */}
                                <div className="logo-header mostion logo-dark">
                                    <Link to={"/"}><img src={IMAGES.logo} alt="" /></Link>
                                </div>

                                <button
                                    className={`navbar-toggler navicon justify-content-end ${sidebarOpen ? 'open' : 'collapsed'}`}
                                    type="button"
                                    onClick={() => setSidebarOpen(!sidebarOpen)}

                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>

                                {/* <!-- Header Nav --> */}
                                <div id="navbarNavDropdown"
                                    className={`header-nav navbar-collapse collapse justify-content-end ${sidebarOpen ? "show" : ""}`}

                                >
                                    <div className="logo-header logo-dark">
                                        <Link to={"/"}><img src={IMAGES.logo} alt="" /></Link>
                                    </div>
                                    <ul className="nav navbar-nav navbar navbar-left">
                                        {MenuListArray2.map((item, index) => {

                                            let menuClass = item.classChange;
                                            if (menuClass !== "sub-menu-down") {
                                                return (
                                                    <li
                                                        key={index}
                                                        className={`${menuClass} ${location.pathname === item.to ? 'active' : ''}`}
                                                    //className={`${ menuClass} ${location.pathname === item.to ? 'active'  : '' }`}  
                                                    >
                                                        <HashLink smooth onClick={() => { handleMenuActive(item.to) }} to={item.to}> {t(`1:${item.id}`)} </HashLink>
                                                    </li>
                                                )
                                            } else {

                                                return (
                                                    <li className={`${menuClass} ${state.active === item.title ? 'open active' : ''} ${item.title === activeMenu ? 'active' : ''}`}
                                                        // <li className={`${ menuClass} ${ location.pathname == item.to ? 'active'  : '' }`}
                                                        key={index}
                                                    >

                                                        {item.content && item.content.length > 0 ?
                                                            <>
                                                                <HashLink smooth to={"#"}
                                                                    onClick={() => { handleMenuActive(item.to) }}
                                                                >
                                                                    {item.title}
                                                                </HashLink>
                                                                <Collapse in={state.active === item.title ? true : false}>
                                                                    <ul className={`sub-menu ${menuClass === "mm-collapse" ? "open" : ""}`}>
                                                                        {item.content && item.content.map((data, index) => {
                                                                            //console.log('path loc', data.to );							
                                                                            return (
                                                                                <li key={index}
                                                                                    className={`${state.activeSubmenu === data.title ? "open" : ""}`}
                                                                                //className={`${ menuClass} ${ location.pathname == data.to ? 'active'  : '' }`}  
                                                                                >
                                                                                    {data.content && data.content.length > 0 ?
                                                                                        <>
                                                                                            <HashLink smooth to={data.to}
                                                                                                onClick={() => {
                                                                                                    handleSubmenuActive(data.to);
                                                                                                }}
                                                                                            >
                                                                                                {data.title}
                                                                                                <i className="fa fa-angle-right" />
                                                                                            </HashLink>
                                                                                            <Collapse in={state.activeSubmenu === data.title ? true : false}>
                                                                                                <ul className={`sub-menu ${menuClass === "mm-collapse" ? "open" : ""}`}>
                                                                                                    {data.content && data.content.map((data, index) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <li key={index}>
                                                                                                                    <Link to={data.to}>{data.title}</Link>
                                                                                                                </li>
                                                                                                            </>
                                                                                                        )
                                                                                                    })}
                                                                                                </ul>
                                                                                            </Collapse>
                                                                                        </>
                                                                                        :
                                                                                        <HashLink smooth onClick={e => e.preventDefault()} to={data.to}>
                                                                                            {data.title}
                                                                                        </HashLink>
                                                                                    }
                                                                                </li>

                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </Collapse>
                                                            </>
                                                            :
                                                            <HashLink to={item.to} >
                                                                {item.title}
                                                            </HashLink>
                                                        }

                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>

                                    <ul><div className="dz-social-icon">
                                        <li>
                                            <a target="_blank" href="https://m.facebook.com/profile.php?id=100095082023703" rel="noreferrer">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>{" "}
                                        <li>
                                            <a target="_blank" href="https://instagram.com/fit4her_leuven?igshid=MzMyNGUyNmU2YQ==" rel="noreferrer">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    </div>
                                        <li>
                                            <center>
                                                <div className="extra-cell">
                                                <div style={{fontSize: 16}} onClick={() => i18n.changeLanguage("en")} className="btn btn-secondary btn-skew" >EN</div>
                                                <div style={{fontSize: 16}} onClick={() => i18n.changeLanguage("nl")} className="btn btn-secondary btn-skew" >NL</div>
                                                    <HashLink to={"http://fit4her.fitman.eu"} className="btn btn-primary btn-skew appointment-btn"><span>{t("1:8")}</span></HashLink>
                                                </div>
                                            </center>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Main Header End --> */}
            </header>
        </>
    );
};

export default Header;